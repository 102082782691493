<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data 
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute || load">Сохранить</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-info">9</td>
                    <td class="td-numbering table-warning">10</td>
                    <td class="td-numbering table-primary">11</td>
                    <td class="td-numbering table-warning">12</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            title="Удалить выбранные записи" 
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`Очистить ${selectAll ? 'все' : 'выбранные'} записи?`)"/>
                    </div>
                </template>
                <template #cell(name_ru)="data">
                    <div>
                        {{ data.item.name_ru }}
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(unit)="data">
                    <div class="text-center">
                        {{ data.item.unit }}
                    </div>
                </template>
                <template #cell(amount)="data">
                    <template v-if="variantAttribute">
                        <b-form-input v-if="!(['07', '15', '18', '20'].includes(data.item.code))"
                                      class="text-right"
                                      :value="data.item.amount"
                                      @change="v => data.item.amount = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^[0-9]+$')"
                                      @blur="inputFixedVldtn(data.item, 'amount', data.item.amount, 0)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(abonent)="data">
                    <template v-if="variantAttribute">
                        <b-form-input v-if="!(['07', '15', '18', '20'].includes(data.item.code))"
                                      class="text-right"
                                      :value="data.item.abonent"
                                      @change="v => data.item.abonent = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixedVldtn(data.item, 'abonent', data.item.abonent, 2)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(time_based)="data">
                    <template v-if="variantAttribute">
                        <b-form-input v-if="!(['07', '15', '18', '20'].includes(data.item.code))"
                                      class="text-right"
                                      :value="data.item.time_based"
                                      @change="v => data.item.time_based = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixedVldtn(data.item, 'time_based', data.item.time_based, 2)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(payment)="data">
                    <template v-if="variantAttribute">
                        <b-form-input v-if="!(['07', '15', '18', '20'].includes(data.item.code))"
                                      class="text-right"
                                      :value="data.item.payment"
                                      @change="v => data.item.payment = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixedVldtn(data.item, 'payment', data.item.payment, 2)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(cost)="data">
                    <template v-if="variantAttribute">
                        <b-form-input v-if="!(['07', '15', '18', '20'].includes(data.item.code))"
                                      class="text-right"
                                      :value="data.item.cost"
                                      @change="v => data.item.cost = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixedVldtn(data.item, 'cost', data.item.cost, 2)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(months)="data">
                    <template v-if="variantAttribute">
                        <b-form-input v-if="!(['07', '15', '18', '20'].includes(data.item.code))"
                                      class="text-right"
                                      :value="data.item.months"
                                      @change="v => data.item.months = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^[0-9]+$')"
                                      @blur="input12(data.item, 'months', $event.target.value)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(rent)="data">
                    <template v-if="variantAttribute">
                        <b-form-input v-if="!(['07', '15', '18', '20'].includes(data.item.code))"
                                      class="text-right"
                                      :value="data.item.rent"
                                      @change="v => data.item.rent = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixedVldtn(data.item, 'rent', data.item.rent, 2)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(traffic)="data">
                    <template v-if="variantAttribute">
                        <b-form-input v-if="!(['07', '15', '18', '20'].includes(data.item.code))"
                                      class="text-right"
                                      :value="data.item.traffic"
                                      @change="v => data.item.traffic = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixedVldtn(data.item, 'traffic', data.item.traffic, 2)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">
                        {{ $n(data.value) }}
                    </div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="openModalRowFilesByRowId(data.item.id, false)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <b-dropdown v-if="variantAttribute" id="dropdown-dropleft" dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <template>
                            <b-dropdown-item @click="openModalRowFilesByRowId(data.item.id, true)">
                                Добавить файл(ы)
                            </b-dropdown-item>
                            <b-dropdown-item v-if="variantAttribute" @click="deleteItem('Очистить запись?', data.item, data.index)">
                                Удалить
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>

                <template #bottom-row="data">
                    <td class="text-right" colspan="11">ИТОГО</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="3"></td>
                </template>
            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-attach ref="modalAttach"
                      :row-files-input="row_files"
                      :header="header"
                      :is-add="isAdd"
                      :variant-attribute="variantAttribute"
                      @toggleIsAdd="toggleIsAdd($event)"
                      @fileUpload="fileUpload"/>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
// import BudgetAttachFile from "./components/budget-attach-file";
import ModalAttach from '@/modules/budget-request/components/modal-attach.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_152',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, ModalAttach, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-152',
                name_ru: 'Расчет расходов на оплату услуг связи',
                name_kk: 'Байланыс қызметтерiне ақы төлеуге жұмсалған шығыстарды есептеу'
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'unit',
                    label: 'Единица измерения'
                },
                {
                    key: 'amount',
                    label: 'Кол-во номеров (точек, каналов) (ед.)'
                },
                {
                    key: 'abonent',
                    label: 'Абонентская плата на 1 единицу в месяц (тенге)'
                },
                {
                    key: 'time_based',
                    label: 'Повременная оплата на 1 ед. в месяц (тенге)'
                },
                {
                    key: 'payment',
                    label: 'Размер оплаты 1 раз в год за использование канала связи (тенге)'
                },
                {
                    key: 'cost',
                    label: 'Средние затраты за месяц на 1 единицу (тенге)'
                },
                {
                    key: 'months',
                    label: 'Число месяцев'
                },
                {
                    key: 'rent',
                    label: 'Арендная плата на 1 ед. в месяц'
                },
                {
                    key: 'traffic',
                    label: 'Плата за трафик в месяц'
                },
                {
                    key: 'total',
                    label: 'Сумма затрат ((гр.4 х гр.8+гр.5 х гр.8+гр.6+гр.7 х гр.8+гр.9 х гр.8+гр.10 х гр.8) х гр.3)/1000 (тыс.тенге)'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: null,
            files: null,
            load: true,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },

    async mounted() {
        await this.loadDict();
    },

    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
            this.$refs.modalAttach.delFile(fileId);
        },

        deleteItem(msg, row = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            this.itemUpdate(row);
                        } else {
                            this.budgetForm.forEach(item => {
                                if (item.itemToDelete) {
                                    this.itemUpdate(item);
                                };
                            });
                        }
                        this.prepareForSave();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления записи', error.toString());
                });
        }, // очистить значения строки

        async delete(item) {
            this.$set(item, 'form', this.form.code);
            try {
                this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'total'));
                const response = await fetch('/api-py/delete-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Запись удалена');
                    // await this.saveTotal();
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
            }
        }, // удаление данных

        downloadRep() {
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    data: this.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма ' + this.form.code + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        input12(item, field, value) {
            // nextTick добавлен тк не без него не работает реактивность при событии keyPress.enter если предыдущее значение = 0 
            this.$nextTick(() => {
                const newVal = parseFloat(value) ? parseFloat(parseFloat(value).toFixed(0)) : 0
                if (parseInt(newVal) > 12) {
                    this.$set(item, field, 0);
                    this.makeToast('danger', 'Предупреждение', 'Требуется ввести число <= 12');
                } else {
                    this.$set(item, field, newVal);
                }
            })
        }, // проверка value>12

        async itemUpdate(item) {
            this.$set(item, 'amount', 0);
            this.$set(item, 'abonent', 0.0);
            this.$set(item, 'time_based', 0.0);
            this.$set(item, 'payment', 0.0);
            this.$set(item, 'cost', 0.0);
            this.$set(item, 'months', 0);
            this.$set(item, 'rent', 0.0);
            this.$set(item, 'traffic', 0.0);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса itemUpdate', error.toString());
            }
            this.load = false;
        },

        keyPress(event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13(event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            that.load = true;
            that.budgetForm.splice(0);
            for (const row of that.dict) {
                const item = {
                    id: this.newRowStartId,
                    id_dict: row.id,
                    par_id: row.par_id,
                    code: row.code,
                    name_ru: (row.order !== null ? row.order + '. ' : '') + row.name_ru,
                    itemToDelete: false,
                    unit: row.unit
                };
                this.newRowStartId--;

                that.itemUpdate(item);

                if (['07', '15', '18', '20'].includes(row.code)) {
                    Object.defineProperty(item, 'total', {
                        get: function () {
                            return that.reSum(item, 'total');
                        }
                    });
                } else {
                    Object.defineProperty(item, 'total', {
                        get: function () {
                            const abonent = (isNaN(parseFloat(item.abonent)) ? 0 : parseFloat(item.abonent));
                            const time_based = (isNaN(parseFloat(item.time_based)) ? 0 : parseFloat(item.time_based));
                            const payment = (isNaN(parseFloat(item.payment)) ? 0 : parseFloat(item.payment));
                            const cost = (isNaN(parseFloat(item.cost)) ? 0 : parseFloat(item.cost));
                            const rent = (isNaN(parseFloat(item.rent)) ? 0 : parseFloat(item.rent));
                            const traffic = (isNaN(parseFloat(item.traffic)) ? 0 : parseFloat(item.traffic));
                            const months = (isNaN(parseInt(item.months)) ? 0 : parseInt(item.months));
                            const amount = (isNaN(parseInt(item.amount)) ? 0 : parseInt(item.amount));

                            const tot = ((abonent * months)
                                + (time_based * months)
                                + payment
                                + (cost * months)
                                + (rent * months)
                                + (traffic * months)) * amount / 1000;

                            return parseFloat((Math.round(tot * 100) / 100).toFixed(2));
                        }
                    });
                }
                that.budgetForm.push(item);
            }

            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(that.header));
                values = await response.json();
                if (response.status === 200) {
                    if (values.length === 0) {
                        that.load = false;
                        return;
                    }
                } else {
                    this.budgetForm.splice(0);
                    this.makeToast('danger', 'Ошибка запроса loadDatas()', `Error code: ${response.status}`);
                }
            } catch (error) {
                this.budgetForm.splice(0);
                that.makeToast('danger', 'Ошибка запроса loadDatas()', error.toString());
            }

            await values.forEach(val => {
                const itemExist = that.budgetForm.filter(function (item) {
                    if (item.code === val.communication) {
                        return item;
                    }
                });

                if (itemExist.length > 0) {
                    itemExist[0].id = val.id;
                    itemExist[0].amount = val.amount;
                    itemExist[0].abonent = val.abonent;
                    itemExist[0].time_based = val.time_based;
                    itemExist[0].payment = val.payment;
                    itemExist[0].cost = val.cost;
                    itemExist[0].months = val.months;
                    itemExist[0].rent = val.rent;
                    itemExist[0].files = val.files;
                    itemExist[0].row_files = val.row_files;
                    itemExist[0].traffic = val.traffic;
                }
            });
            that.load = false;
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/communications/');
                this.dict = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDict()', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            let months12 = false;
            const values = [];
            for (const row of this.budgetForm) {
                if (!parseFloat(row.months) || row.months > 12) {
                    this.$set(row, 'months', 0);
                    months12 = true;
                }
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'communication', row.code);
                this.$set(item, 'amount', parseInt(row.amount));
                this.$set(item, 'abonent', parseFloat(row.abonent));
                this.$set(item, 'time_based', parseFloat(row.time_based));
                this.$set(item, 'payment', parseFloat(row.payment));
                this.$set(item, 'cost', parseFloat(row.cost));
                this.$set(item, 'months', parseInt(row.months));
                this.$set(item, 'rent', parseFloat(row.rent));
                this.$set(item, 'traffic', parseFloat(row.traffic));
                this.$set(item, 'row_files', row.row_files);

                values.push(item);
            }
            if (months12) {
                // this.makeToast('danger', 'Предупреждение', 'В поле "Число месяцев" значения > 12 будут приравнены нулю ');
            }
            if (values.length > 0 && this.variantAttribute) {
                this.save(values);
            } else {
                this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
            }
        }, // подготовка к сохранению

        reSum(parent, field) {
            let sum = 0;
            this.budgetForm.forEach(row => {
                if (row.par_id === parent.id_dict) {
                    sum += parseFloat(row[field]);
                }
            });
            return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        async save(values) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const response = await fetch('/api-py/save_brform01_152/' +
                    JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // await this.saveTotal();
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    this.selectAll = false;
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    await this.loadDatas();
                } else {
                    this.selectAll = false;
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                const response = await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        } // сохранение итога
    },

    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (row.par_id === null) {
                    sum += parseFloat(row.total);
                }
            }
            return Math.ceil(sum);
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
</style>